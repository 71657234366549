import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { FaChevronDown } from 'react-icons/fa6';
import { NavigationInstructionsType } from '../../visioweb/visiomap';
import { NavigationChoiceToggle } from './navigation-choice-toggle';
import { Essential, Floor, InstructionData } from '../../../types/visiomap';
import { Preference } from '../../visioweb/custom-route';
import { useLocalization } from '../../../localization/use-localization';
import { singleFloor } from '../../../helpers/floors';
import { getGroundFloor } from '../../../helpers/floors';
import ButtonClickArea from '../../button-click-area/button-click-area';

type Props = {
    onClose: () => void;
    useAccessibleRoute: boolean;
    toggleAccessibleRoute: () => void;
    directions: InstructionData[];
    activeInstructionIndex: number;
    setActiveInstructionIndex: React.Dispatch<React.SetStateAction<number>>;
    routePreference: Preference;
    setRoutePreference: (choice: Preference) => void;
    goToNavigationInstruction: (instruction: InstructionData) => void;
    visioWebEssential: Essential | null;
    activeFloorPlanId: string | null;
};

type ParsedInstruction = {
    parsedIndex: number;
    floor: Floor;
    instructions: NavigationInstructionsType[];
};

export const Directions = ({
    onClose,
    routePreference,
    setRoutePreference,
    directions,
    activeInstructionIndex,
    setActiveInstructionIndex,
    goToNavigationInstruction,
    visioWebEssential,
}: Props) => {
    const { getTranslation } = useLocalization();

    const [activeLevel, setActiveLevel] = useState<string | null>(() =>
        typeof directions[0]?.floor?.id !== 'undefined' ? directions[0]?.floor?.id : null
    );

    const getLocalizedFloor = (floorId: string | null) =>
        visioWebEssential?.venue?.getLocalizedShortName({ id: floorId || '' }) || floorId || '';
    const isSingleFloor = useMemo(() => singleFloor(visioWebEssential), [visioWebEssential]);

    const getParsedDirections = useMemo(() => {
        const groundFloor = getGroundFloor(visioWebEssential) || undefined; // function will return null that we must handle. Funny though, the SDK model will expect undefined...
        const parsedDirections = directions.map(instruction => ({
            ...instruction,
            floor: instruction.floor || groundFloor, // instructions for outside "floors" will have undefined floor, so we want it to use the ground floor instead
        }));

        return parsedDirections.reduce((parsedInstrunctions, instruction, currentIndex) => {
            if (!instruction.floor) {
                return parsedInstrunctions;
            }

            const previousInstruction = parsedInstrunctions.length - 1;

            if (parsedInstrunctions[previousInstruction]?.floor?.id === instruction?.floor?.id) {
                parsedInstrunctions[previousInstruction].instructions.push(instruction);
            } else {
                parsedInstrunctions.push({
                    parsedIndex: currentIndex,
                    floor: instruction?.floor || groundFloor, // groundFloor is a fallback and we'll never get here if it was missing
                    instructions: [instruction],
                });
            }

            return parsedInstrunctions;
        }, [] as ParsedInstruction[]);
    }, [directions, visioWebEssential]);

    const hasDirectionsChanged = useMemo(() => {
        if (getParsedDirections.length > 1) {
            return true;
        }
        let containsPRM = false;

        for (const instruction of getParsedDirections[0].instructions) {
            if (instruction.attributes.indexOf('notPRM') != -1) {
                containsPRM = true;
            }
        }
        return containsPRM;
    }, [getParsedDirections]);

    const getCurrentLevel = useMemo(() => {
        const initialFloor = getParsedDirections[0];
        return initialFloor ? initialFloor.floor.id : null;
    }, [getParsedDirections]);

    const getSectionHeader = (floorId: string) => {
        if (isSingleFloor) {
            return getTranslation('Directions');
        }

        const floorName = getLocalizedFloor(floorId);
        return activeLevel === floorId
            ? `${getTranslation('Directions on level %s').replace('%s', floorName)}`
            : `${getTranslation('Show directions on level %s').replace('%s', floorName)}`;
    };

    const goToInstruction = (instruction: NavigationInstructionsType) => {
        if (!instruction?.floor?.id) {
            return;
        }

        setActiveLevel(instruction.floor.id);
        setActiveInstructionIndex(instruction.instructionIndex);
        goToNavigationInstruction(instruction);
    };

    useEffect(() => {
        setActiveLevel(getCurrentLevel || '');
        setActiveInstructionIndex(0);

        return () => {
            setActiveLevel(null);
            setActiveInstructionIndex(0);
        };
    }, [directions, getCurrentLevel, setActiveInstructionIndex]);

    return (
        <div>
            <div className="-mx-4 -mt-1 h-16 bg-accent" />
            <div className="flex h-16 w-full cursor-pointer items-center bg-white/95">
                <ButtonClickArea onClick={() => onClose()}>
                    <IoArrowBack className="text-4xl" onClick={() => {}} />
                </ButtonClickArea>
            </div>

            <div className="mb-8">
                <div className="mb-16 mt-8 flex flex-col items-center space-y-8">
                    <img src="static/img/you-are-here.svg" className="w-32" />
                    <h2 className="text-3xl font-bold">
                        {isSingleFloor ? '' : getTranslation('You are on level %s').replace('%s', getLocalizedFloor(getCurrentLevel) || '')}
                    </h2>
                    {hasDirectionsChanged ? (
                        <NavigationChoiceToggle routePreference={routePreference} setRoutePreference={setRoutePreference} />
                    ) : null}
                </div>
                {/* <div className="mb-1 px-4">
                    <QrCodeNav activeFloorPlanId={activeFloorPlanId} routePreference={routePreference} />
                </div> */}

                {getParsedDirections.map(step => (
                    <div key={step.parsedIndex}>
                        <button
                            onClick={() => goToInstruction(step.instructions[0])}
                            className={clsx('grey-button flex items-center justify-between gap-4 text-lg', {
                                'cursor-default': step.instructions.some(i => i.instructionIndex === activeInstructionIndex),
                            })}
                        >
                            <span>{getSectionHeader(step.floor.id)}</span>
                            {hasDirectionsChanged ? (
                                <span
                                    className={clsx('rounded-full bg-[#eae3da] p-2 transition-transform duration-300 ease-out', {
                                        'rotate-180': step.instructions.some(i => i.instructionIndex === activeInstructionIndex),
                                    })}
                                >
                                    <FaChevronDown size={12} color="#767571" />
                                </span>
                            ) : null}
                        </button>
                        <div
                            className={clsx(
                                'grid transition-[grid-template-rows] duration-300 ease-out',
                                step.instructions.some(i => i.instructionIndex === activeInstructionIndex)
                                    ? 'grid-rows-[1fr] py-6'
                                    : 'grid-rows-[0fr] py-2'
                            )}
                        >
                            <ul className="overflow-hidden">
                                {step.instructions.map(instruction => (
                                    <li
                                        key={instruction.instructionIndex}
                                        onClick={() => goToInstruction(instruction)}
                                        className={clsx(
                                            'mb-1 flex cursor-pointer items-center gap-4 rounded-2xl p-3 align-middle xl:mx-2',
                                            {
                                                'underline underline-offset-1': instruction.instructionIndex === activeInstructionIndex,
                                            }
                                        )}
                                    >
                                        <img className="w-10 opacity-80" src={instruction.icon} />
                                        <span>{instruction.detail}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
