import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { getAnchorLink } from '../../../helpers/shops';
import { useShops } from '../../../hooks/use-shops';
import { Essential } from '../../../types/visiomap';
import { List } from '../list-components/list';

type Props = {
    visioWebEssential: Essential | null;
    onClick: (id?: string) => void;
};

export const AtoZed = ({ visioWebEssential, onClick }: Props) => {
    const { getAToZlist } = useShops(visioWebEssential);
    const [activeLetter, setActiveLetter] = useState<string | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const aToZlist = getAToZlist();

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (activeLetter) {
            timeout = setTimeout(() => {
                setActiveLetter(null);
            }, 1000);

            const item = containerRef.current?.querySelector(`div[data-letter-id="${getAnchorLink(activeLetter)}"]`);

            if (item) {
                item.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [activeLetter]);

    return (
        <div className="relative flex h-full max-h-full flex-col gap-4 overflow-hidden" ref={containerRef}>
            <List
                items={aToZlist.filter(item => item.items.length)}
                onClick={onClick}
                className="scrollbar-show mx-2 flex-[1_1_100%] overflow-auto px-2 py-4"
            />
            <div className="mx-2 flex flex-auto flex-wrap gap-2 bg-white px-2 pb-4 text-2xl font-bold xl:top-32 xl:pb-8">
                {aToZlist.map(({ title, items }, index) => (
                    <button
                        key={index}
                        disabled={!items.length}
                        data-tracking="a-to-z-item"
                        onClick={() => setActiveLetter(title)}
                        className={clsx(
                            'flex h-10 w-10 cursor-pointer items-center justify-center gap-2 rounded-full text-center transition-all disabled:cursor-default disabled:opacity-40',
                            {
                                'bg-accent': activeLetter === title,
                                hidden: title === '#', // quickfix to hide the non-alphabetical character button
                            }
                        )}
                    >
                        {title}
                    </button>
                ))}
            </div>
        </div>
    );
};
